import * as v from 'valibot'

const actionTypes = v.union([
  v.literal('BOOK'),
  v.literal('ORDER'),
  v.literal('SHOP'),
  v.literal('LEARN_MORE'),
  v.literal('SIGN_UP'),
  v.literal('CALL'),
])

export const post = v.object({
  id: v.string(),
  title: v.nullable(v.string()),
  summary: v.nullable(v.string()),
  layout: v.nullable(
    v.object({
      title: v.string(),
      description: v.string(),
    }),
  ),
  event: v.nullable(
    v.object({
      startedAt: v.string(),
      endedAt: v.string(),
    }),
  ),
  callToAction: v.nullable(
    v.object({
      actionType: actionTypes,
      url: v.string(),
    }),
  ),
  images: v.array(v.string()),
  createdAt: v.string(),
  updatedAt: v.string(),
})

export type CallToActionTypes = v.InferOutput<typeof actionTypes>

const conversionButton = v.object({
  agentType: v.string(),
  buttonType: v.string(),
  label: v.string(),
  value: v.string(),
  openInAnotherTab: v.boolean(),
})

const contentAttributeItem = v.object({
  id: v.number(),
  label: v.string(),
  imageUrl: v.nullish(v.string()),
  imageAlt: v.nullish(v.string()),
  pdfUrl: v.nullish(v.string()),
  setting: v.object({
    content: v.nullish(
      v.object({
        url: v.nullish(v.string()),
        description: v.nullish(v.string()),
        text: v.nullish(v.string()),
      }),
    ),
  }),
})

const contentAttribute = v.object({
  id: v.number(),
  label: v.string(),
  type: v.string(),
  imageSize: v.string(),
  setting: v.object({
    content: v.object({
      url: v.nullish(v.string()),
      description: v.nullish(v.string()),
      text: v.nullish(v.string()),
    }),
    imageUrl: v.nullable(v.string()),
    imageAlt: v.nullable(v.string()),
  }),
  list: v.array(contentAttributeItem),
})

export const businessHours = v.object({
  regularHours: v.nullish(
    v.object({
      monday: v.object({
        open: v.boolean(),
        times: v.array(
          v.object({
            openTime: v.string(),
            closeTime: v.string(),
            lastOrderTime: v.string(),
          }),
        ),
      }),
      tuesday: v.object({
        open: v.boolean(),
        times: v.array(
          v.object({
            openTime: v.string(),
            closeTime: v.string(),
            lastOrderTime: v.string(),
          }),
        ),
      }),
      wednesday: v.object({
        open: v.boolean(),
        times: v.array(
          v.object({
            openTime: v.string(),
            closeTime: v.string(),
            lastOrderTime: v.string(),
          }),
        ),
      }),
      thursday: v.object({
        open: v.boolean(),
        times: v.array(
          v.object({
            openTime: v.string(),
            closeTime: v.string(),
            lastOrderTime: v.string(),
          }),
        ),
      }),
      friday: v.object({
        open: v.boolean(),
        times: v.array(
          v.object({
            openTime: v.string(),
            closeTime: v.string(),
            lastOrderTime: v.string(),
          }),
        ),
      }),
      saturday: v.object({
        open: v.boolean(),
        times: v.array(
          v.object({
            openTime: v.string(),
            closeTime: v.string(),
            lastOrderTime: v.string(),
          }),
        ),
      }),
      sunday: v.object({
        open: v.boolean(),
        times: v.array(
          v.object({
            openTime: v.string(),
            closeTime: v.string(),
            lastOrderTime: v.string(),
          }),
        ),
      }),
    }),
  ),
  holidayRegularHour: v.nullish(
    v.object({
      open: v.boolean(),
      times: v.array(
        v.object({
          openTime: v.string(),
          closeTime: v.string(),
          lastOrderTime: v.string(),
        }),
      ),
    }),
  ),
  dayBeforeHolidayRegularHour: v.nullish(
    v.object({
      open: v.boolean(),
      times: v.array(
        v.object({
          openTime: v.string(),
          closeTime: v.string(),
          lastOrderTime: v.string(),
        }),
      ),
    }),
  ),
  specialHours: v.array(
    v.object({
      date: v.string(),
      open: v.boolean(),
      times: v.array(
        v.object({
          openTime: v.string(),
          closeTime: v.string(),
          lastOrderTime: v.string(),
        }),
      ),
    }),
  ),
  regularHourAdditionalDescription: v.nullish(v.string()),
  regularHolidayAdditionalDescription: v.nullish(v.string()),
})

const nearbyStore = v.object({
  id: v.string(),
  name: v.string(),
  kcomName: v.string(),
  address: v.nullish(
    v.object({
      prefecture: v.number(),
      postalCode: v.string(),
      lines: v.array(v.string()),
      latitude: v.nullish(v.number()),
      longitude: v.nullish(v.number()),
      googleMapIframe: v.nullable(v.string()),
    }),
  ),
  distance: v.nullish(v.number()),
  brand: v.object({
    id: v.number(),
    name: v.string(),
    imageUrl: v.string(),
  }),
  open: v.string(),
  phone: v.nullish(
    v.object({
      number: v.string(),
      comment: v.nullish(v.string()),
    }),
  ),
  businessHours: businessHours,
})

export const store = v.object({
  id: v.string(),
  name: v.string(),
  kcomName: v.string(),
  address: v.nullish(
    v.object({
      prefecture: v.number(),
      city: v.nullish(v.string()),
      postalCode: v.string(),
      lines: v.array(v.string()),
      latitude: v.nullish(v.number()),
      longitude: v.nullish(v.number()),
      googleMapIframe: v.nullable(v.string()),
    }),
  ),
  distance: v.nullish(v.number()),
  brand: v.object({
    id: v.number(),
    name: v.string(),
    imageUrl: v.string(),
  }),
  open: v.string(),
  storeAdditionalDescription: v.nullish(v.string()),
  phone: v.nullish(
    v.object({
      number: v.string(),
      comment: v.nullish(v.string()),
    }),
  ),
  layout: v.object({
    title: v.string(),
    description: v.string(),
  }),
  businessHours: businessHours,
  conversionButtons: v.nullish(v.array(conversionButton)),
  imageSetting: v.object({
    title: v.nullish(v.string()),
    description: v.nullish(v.string()),
  }),
  images: v.array(
    v.object({
      url: v.string(),
      alt: v.string(),
    }),
  ),
  postsLayout: v.object({
    title: v.string(),
    description: v.string(),
  }),
  posts: v.array(post),
  sns: v.object({
    facebookUrl: v.nullish(v.string()),
    instagramUrl: v.nullish(v.string()),
    lineUrl: v.nullish(v.string()),
    twitterUrl: v.nullish(v.string()),
  }),
  nearbyStores: v.array(nearbyStore),
  contentAttributes: v.array(contentAttribute),
})

export const stores = v.object({
  data: v.array(store),
})

export type Stores = v.InferOutput<typeof stores>
