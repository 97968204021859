import * as v from 'valibot'

// ブランド
export const brand = v.object({
  id: v.number(),
  name: v.string(),
  imageUrl: v.string(),
})

// ページコンテンツ設定
export const contentAttribute = v.object({
  id: v.number(),
  label: v.string(),
  type: v.string(),
  useSearch: v.boolean(),
  displaySearchResult: v.boolean(),
  list: v.array(
    v.object({
      id: v.number(),
      label: v.string(),
      imageUrl: v.nullable(v.string()),
      imageAlt: v.nullable(v.string()),
    }),
  ),
})

// お知らせ
export const note = v.object({
  createdAt: v.string(),
  title: v.string(),
  url: v.string(),
})

// ヘッダーとフッターのリンク
const linkItem = v.object({
  label: v.string(),
  url: v.string(),
})

// テンプレートレイアウト
const templateLayout = v.object({
  headerLogo: v.nullable(v.string()),
  headerLogoLinkUrl: v.string(),
  headerLogoLinkOpenInAnotherTab: v.boolean(),
  footerLogo: v.nullable(v.string()),
  copyright: v.string(),
  headers: v.array(linkItem),
  footers: v.array(linkItem),
})

// マスター設定
export const setting = v.object({
  companyName: v.string(),
  googleAnalyticsPropertyId: v.string(),
  googleTagManagerContainerId: v.string(),
  searchConsoleVerificationCode: v.string(),
  locationSearch: v.boolean(),
  prefectureSearch: v.boolean(),
  keywordSearch: v.boolean(),
  brandSearch: v.boolean(),
  brands: v.array(brand),
  displayPhoneNumber: v.boolean(),
  displayImage: v.boolean(),
  displayNearbyStore: v.boolean(),
  coverImage: v.nullable(
    v.object({
      pcImageUrl: v.nullable(v.string()),
      spImageUrl: v.nullable(v.string()),
      imageAlt: v.string(),
    }),
  ),
  faviconFileName: v.nullable(v.string()),
  availableLanguages: v.array(v.string()),
  siteUrl: v.nullable(v.string()),
  layout: v.object({
    headerHtml: v.string(),
    headerCss: v.string(),
    footerHtml: v.string(),
    footerCss: v.string(),
    javascript: v.string(),
    primaryColorCode: v.nullable(v.string()),
    secondaryColorCode: v.nullable(v.string()),
    tertiaryColorCode: v.nullable(v.string()),
    searchesTopTitle: v.string(),
    searchesTopDescription: v.string(),
    searchesIndexTitle: v.string(),
    searchesIndexDescription: v.string(),
  }),
  templateLayout: templateLayout,
  contentAttributes: v.array(contentAttribute),
  notices: v.array(note),
})
