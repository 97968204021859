import * as v from 'valibot'

const city = v.object({
  code: v.string(),
  name: v.string(),
})

const prefecture = v.object({
  id: v.number(),
  name: v.string(),
  cities: v.array(city),
})

export const prefectures = v.object({
  data: v.array(prefecture),
})

export type City = v.InferOutput<typeof city>
export type Prefectures = v.InferOutput<typeof prefectures>
