interface Breadcrumb {
  label: string
  link?: object
}

export const useBreadcrumb = () => {
  const breadcrumb = useState<Breadcrumb[]>('breadcrumb', () => [])
  const updateBreadcrumb = (updateBreadcrumb: Breadcrumb[]) => {
    breadcrumb.value = updateBreadcrumb
  }
  return {
    breadcrumb: breadcrumb,
    updateBreadcrumb,
  }
}
