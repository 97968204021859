import * as v from 'valibot'
import { setting, stores, prefectures } from '~/schema'

// マスター設定
export const useSettingForPageBuild = () => {
  const { locale } = useI18n()
  return useAsyncData('setting', async () => {
    let res
    switch (locale.value) {
      case 'ja':
        res = await queryContent('setting').findOne()
        break
      default:
        res = await queryContent(locale.value, 'setting').findOne()
    }
    if (!res) throw new Error('Failed to fetch data')
    const result = v.safeParse(setting, res)
    if (!result.success) {
      throw new Error(JSON.stringify(v.flatten(result.issues)))
    }
    return result.output
  })
}

// 店舗一覧
export const useStoresForPageBuild = () => {
  const { locale } = useI18n()
  return useAsyncData(`build_data/stores`, async () => {
    let res
    switch (locale.value) {
      case 'ja':
        res = await queryContent('stores').findOne()
        break
      default:
        res = await queryContent(locale.value, 'stores').findOne()
    }
    if (!res) throw new Error('Failed to fetch data')
    const result = v.safeParse(stores, res)
    if (!result.success) {
      throw new Error(JSON.stringify(v.flatten(result.issues)))
    }
    return result.output.data
  })
}

// 店舗詳細
export const useStoreForPageBuild = (storeId: string) => {
  const { locale } = useI18n()
  return useAsyncData(`build_data/stores/${storeId}`, async () => {
    let res
    switch (locale.value) {
      case 'ja':
        res = await queryContent('stores').findOne()
        break
      default:
        res = await queryContent(locale.value, 'stores').findOne()
    }
    if (!res) throw new Error('Failed to fetch data')

    const result = v.safeParse(stores, res)
    if (!result.success) {
      throw new Error(JSON.stringify(v.flatten(result.issues)))
    }
    const parsedStores = result.output.data
    const store = parsedStores.find((store) => store.id === storeId)
    if (store == null) return

    return store
  })
}

// 都道府県データ
export const usePrefecturesForPageBuild = () => {
  const { locale } = useI18n()
  return useAsyncData(`build_data/prefectures`, async () => {
    let res
    switch (locale.value) {
      case 'ja':
        res = await queryContent('cities_in_japan').findOne()
        break
      default:
        res = await queryContent(locale.value, 'cities_in_japan').findOne()
    }

    if (!res) throw new Error('Failed to fetch data')
    const result = v.safeParse(prefectures, res)
    if (!result.success) {
      throw new Error(JSON.stringify(v.flatten(result.issues)))
    }
    return result.output.data
  })
}
